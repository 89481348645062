import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconEmptyWatchlist: TIconComponent = props => (
    <Icon fill="none" viewBox="0 0 166 122" {...props}>
        <g clipPath="url(#clip0_2474_9685)">
            <path d="M45.7681 112.49V108.312" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M90.0942 65.58H111.9" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M67.168 65.58H81.84" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M59.1581 67.602C60.2841 67.602 61.1961 66.69 61.1961 65.564C61.1961 64.438 60.2841 63.526 59.1581 63.526C58.0321 63.526 57.1201 64.438 57.1201 65.564C57.1201 66.69 58.0321 67.602 59.1581 67.602Z"
                fill="#333333"
                stroke="#333333"
                strokeWidth="3.06"
                strokeMiterlimit="10"
            />
            <path d="M87.2422 80.95L112.05 80.942" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M67.168 80.95H79.038" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M59.1581 82.964C60.2841 82.964 61.1961 82.052 61.1961 80.926C61.1961 79.8 60.2841 78.888 59.1581 78.888C58.0321 78.888 57.1201 79.8 57.1201 80.926C57.1201 82.052 58.0321 82.964 59.1581 82.964Z"
                fill="#333333"
                stroke="#333333"
                strokeWidth="3.06"
                strokeMiterlimit="10"
            />
            <path d="M89.2803 95.622L112.05 95.614" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M67.168 95.614H81.128" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M59.1581 97.636C60.2841 97.636 61.1961 96.724 61.1961 95.598C61.1961 94.472 60.2841 93.56 59.1581 93.56C58.0321 93.56 57.1201 94.472 57.1201 95.598C57.1201 96.724 58.0321 97.636 59.1581 97.636Z"
                fill="#333333"
                stroke="#333333"
                strokeWidth="3.06"
                strokeMiterlimit="10"
            />
            <path
                d="M141.31 27.178C141.31 41.276 129.88 52.706 115.782 52.706C101.684 52.706 90.2563 41.276 90.2563 27.178C90.2563 13.08 101.684 1.65002 115.782 1.65002C129.88 1.65002 141.31 13.08 141.31 27.178Z"
                fill="#D5E6A5"
            />
            <path
                d="M141.234 28.638C140.496 42.076 129.366 52.744 115.746 52.744C102.126 52.744 90.2202 41.314 90.2202 27.216C90.2202 13.118 101.648 1.68799 115.746 1.68799C117.372 1.68799 118.964 1.83999 120.506 2.12999"
                stroke="#404040"
                strokeWidth="3.06"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M144.996 52.264C146.21 51.114 148.12 51.14 149.302 52.324L162.262 65.286C164.95 67.972 164.842 72.396 161.942 74.946C159.294 77.272 155.236 76.978 152.744 74.486L139.806 61.548C138.576 60.318 138.604 58.318 139.866 57.122L144.996 52.266V52.264Z"
                fill="#95C11F"
                stroke="#333333"
                strokeWidth="3.06"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M144.659 52.3233L135.612 43.2766L130.898 47.9902L139.945 57.0369L144.659 52.3233Z"
                fill="#95C11F"
                stroke="#333333"
                strokeWidth="3.06"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M45.8101 112.316V108.144" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M120.79 58.528L120.878 120.058" stroke="#333333" strokeWidth="3.06" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M45.8101 102.23L45.9021 47.662C45.9021 46.672 46.2961 45.722 46.9941 45.02L64.6041 27.328C65.3081 26.62 66.2641 26.222 67.2661 26.222H84.6801"
                stroke="#333333"
                strokeWidth="3.06"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M65.8901 26.41V45.928H46.3721" stroke="#333333" strokeWidth="3.06" strokeLinejoin="round" />
            <path
                d="M116.894 8.92201L122.892 20.048L134.922 21.902C135.234 21.95 135.358 22.334 135.134 22.556L126.35 31.22L128.386 43.442C128.436 43.75 128.122 43.986 127.842 43.852L116.614 38.45L105.452 44.128C105.174 44.268 104.852 44.038 104.898 43.728L106.786 31.294L97.8263 22.392C97.6003 22.168 97.7283 21.786 98.0423 21.738L110.78 19.878L116.214 8.93201C116.352 8.65201 116.748 8.64601 116.896 8.92001L116.894 8.92201Z"
                fill="#95C11F"
                stroke="#333333"
                strokeWidth="3.06"
                strokeMiterlimit="10"
            />
        </g>
        <defs>
            <clipPath id="clip0_2474_9685">
                <rect width="121.6" height="121.6" fill="white" transform="translate(44.2002)" />
            </clipPath>
        </defs>
    </Icon>
);
